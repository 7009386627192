// export default App;
import React, { useState } from "react";
import "./App.css";
import OpenLayersMap from "./OpenLayersMap"; // Asegúrate de tener la ruta correcta al componente

// import exampleGeojsons,  {exampleGeojsons2} from "./exampleData/exampleGeojsonValladolid";
// import exampleGeojsonWithdrawalAutonomies from "./exampleData/exampleGeojsonWithdrawalAutonomies"
import exampleBuenosAires from "./exampleData/exampleBuenosAires_consolar.js"
// import exampleGeojsonSolar from "./exampleData/exampleGeojsonSolar"
// //import exampleGeojsonWithdrawalProvinces from "./exampleGeojsonWithdrawalProvinces"
// import exampleGeojsonWithdrawalMunicipe from "./exampleData/exampleGeojsonWithdrawalMunicipe"
//import exampleGeojsonsValencia from "./exampleData/exampleGeojsonValenciaCentroids";
//import exampleGeojsonsValencia from "./exampleData/exampleGeojsonValenciaCentroidsGeojson.geojson";
//import exampleGeojsonsValencia from "./exampleData/exampleGeojsonValencia";
//import exampleValladolid from "./exampleData/exampleGeojsonValladolid.js"
import "./test.css"
// import { Button } from "@mui/material";
//import exampleGeojsons from "./exampleGeojson_malaga.json"
function App() {
	const sampleGeojsons = JSON.stringify([exampleBuenosAires])//JSON.stringify(exampleGeojsonsValencia);
	//const sampleGeojsons2 = JSON.stringify(exampleGeojsonWithdrawalProvinces);
  //const [geojsonUsed, setGeojsonUsed] = useState(1);
  return  <div style={{width:"100vw", height:"100vh", overflow:"hidden"}}>
  <OpenLayersMap
    geojsons ={sampleGeojsons}
    style = {{ width: "100%", height: "100%", display: "flex" }}
    defaultSelectedLayer = "solar_heatmap"
    //layers_data = {layerStyle}
    //getBgColorFromFeature = {(feature)=>{return  "116, 86, 171"}}
    mustShowOnClickFeature = {true}
    isDrawEnabled = {false}
    isDrawEnabledOnStartUp = {false}
    //assetsBasePath = {false}
    isAdressSearchBarEnabled = {true}
    isGetCoordinatesOnClickEnabled = {false}
    isGeolocationEnabled = {false}
    layersSelected ={"solar"}
    base_maps_aviables = {["arcgis"]}
    base_maps_images={["map_street.jpg"]}
  /></div>
}
//   const [dataMustShowOnClickFeature, setDataMustShowOnClickFeature] = useState(true);
//   const [dataIsDrawEnabled, setDataIsDrawEnabled] = useState(false);
//   const [dataIsAddressSearchBarEnabled, setDataIsAddressSearchBarEnabled] = useState(true);
//   const [dataIsGetCoordinatesOnClickEnabled, setDataIsGetCoordinatesOnClickEnabled] = useState(false);
//   const [dataIsDrawEnabledOnStartup, setDataIsDrawEnabledOnStartup] = useState(false);
//   const [isGeolocationEnabled, setIsGeolocationEnabled] = useState(false);
//   const [layerStyle, setLayerStyle] = useState(false);
//   const [dataLayerStyle, setDataLayerStyle] = useState("solar");
//   // const swichGeometry = ()=>{
// 	// if(geojsonUsed%2 == 0){
// 	// 	setGeojsons(sampleGeojsons);
// 	// }else{
// 	// 	//setGeojsons(sampleGeojsons2);
// 	// }
// 	// setGeojsonUsed(geojsonUsed+1)
//   // }

// const the_box_onclick =  function () {
//    setGeojsons( JSON.stringify(exampleGeojsons));
//    setDataLayerStyle( "amianticPublic")

// };
// const the_box_2_onclick =  function () {
//  setGeojsons(JSON.stringify( exampleGeojsons2));
//   setDataLayerStyle( "amianticPublic")

  
// };
// const the_box_regions_onclick =  function () {
//    setGeojsons(JSON.stringify(exampleGeojsonWithdrawalAutonomies));
//     setDataLayerStyle( "regionLimits")

    
// };
// const the_box_amianto_onclick =function () {
//    setGeojsons(JSON.stringify(exampleGeojsonWithdrawalMunicipe));
//     setDataLayerStyle( "susceptibility")

    
// };
// const the_box_solar_onclick=function(){
//   setGeojsons(JSON.stringify(exampleGeojsonSolar));
//   setDataLayerStyle("solar")
//   setDataMustShowOnClickFeature( true);
//   setDataIsDrawEnabled(false);
//   setDataIsAddressSearchBarEnabled( true);
//   setDataIsGetCoordinatesOnClickEnabled(false);
//   setDataIsDrawEnabledOnStartup(false);
//   setIsGeolocationEnabled(false);
// }

// const the_box_3_onclick = function () {
//     var serializedGeojsons3 = JSON.stringify([]);
//    // var map = document.getElementById('olmap');
//     setDataLayerStyle( "amianticPublic")

//    setGeojsons( serializedGeojsons3);
    
// }
// const the_box_inspection_visor = ()=>{
//     setDataMustShowOnClickFeature( true);
//     setDataIsDrawEnabled(true);
//     setDataIsAddressSearchBarEnabled( true);
//     setDataIsGetCoordinatesOnClickEnabled(false);
//     setDataIsDrawEnabledOnStartup(false);
//     setIsGeolocationEnabled(false);
//     setLayerStyle("amianticPublic")

    
// }
// const the_box_inspection_editor = ()=>{
//     setDataMustShowOnClickFeature( false);
//     setDataIsDrawEnabled(false);
//     setDataIsAddressSearchBarEnabled( true);
//     setDataIsGetCoordinatesOnClickEnabled(false);
//     setDataIsDrawEnabledOnStartup(false);
//     setIsGeolocationEnabled(false);
//     setLayerStyle("amianticPublic")

    
// }
// const the_box_inspection_creation = ()=>{
//     setDataMustShowOnClickFeature( true);
//     setDataIsDrawEnabled(false);
//     setDataIsAddressSearchBarEnabled( true);
//     setDataIsGetCoordinatesOnClickEnabled(false);
//     setDataIsDrawEnabledOnStartup(true);
//     setIsGeolocationEnabled(true);
//     setLayerStyle("amianticPublic")

    
// }
// const the_box_public_map = ()=>{
//     setDataMustShowOnClickFeature( true);
//     setDataIsDrawEnabled(false);
//     setDataIsAddressSearchBarEnabled( true);
//     setDataIsGetCoordinatesOnClickEnabled(false);
//     setDataIsDrawEnabledOnStartup(false);
//     setIsGeolocationEnabled(false);
//     setLayerStyle("amianticPublic")

    
// }
// const the_box_public_notification = ()=>{
//     setDataMustShowOnClickFeature( true);
//     setDataIsDrawEnabled(false);
//     setDataIsAddressSearchBarEnabled( true);
//     setDataIsGetCoordinatesOnClickEnabled(true);
//     setDataIsDrawEnabledOnStartup(false);
//     setIsGeolocationEnabled(false);
//     setLayerStyle("amianticPublic")
    
// }
// const the_box_public_region_limit = ()=>{
//     setDataMustShowOnClickFeature( false);
//     setDataIsDrawEnabled(false);
//     setDataIsAddressSearchBarEnabled( true);
//     setDataIsGetCoordinatesOnClickEnabled(false);
//     setDataIsDrawEnabledOnStartup(false);
//     setIsGeolocationEnabled(false);
//     setLayerStyle("regionLimits")
    
// }
// const the_box_public_susceptibility = ()=>{
//     setDataMustShowOnClickFeature( true);
//     setDataIsDrawEnabled(false);
//     setDataIsAddressSearchBarEnabled( true);
//     setDataIsGetCoordinatesOnClickEnabled(false);
//     setDataIsDrawEnabledOnStartup(false);
//     setIsGeolocationEnabled(false);
//     setLayerStyle("susceptibility")
    
// }

//   return (
//     <div className="App">

//       <script src="https://unpkg.com/@ungap/custom-elements-builtin"></script>
//       <script type="module" src="x-frame-bypass.js"></script>
//       <label>Test OpenLayersMap Web Component</label>
//     <div className="the-box-container">
//         <div onClick={the_box_onclick} className="the-box" id="the-box">Geometría Valladolid amiantic</div>
//         <div onClick={the_box_2_onclick} className="the-box the-box2" id="the-box2">Geometría Alpedrete puntos</div>
//         <div onClick={the_box_regions_onclick} className="the-box the-box2" id="the-box-regions">Geometría regiones CCAA</div>
//         <div onClick={the_box_amianto_onclick} className="the-box the-box2" id="the-box-amianto">Geometría amianto municipio</div>
//         <div onClick={the_box_solar_onclick} className="the-box the-box2" id="the-box-amianto">Geometría solar</div>
        
//         <div onClick={the_box_3_onclick} className="the-box the-box3" id="the-box3">Sin geometría</div>
//         <div onClick={the_box_inspection_visor} className="the-box the-box4" id="the-box-inspection-visor">INSPECCIONES - VISOR</div>
//         <div onClick={the_box_inspection_editor} className="the-box the-box4" id="the-box-inspection-editor">INSPECCIONES - EDICION</div>
//         <div onClick={the_box_inspection_creation} className="the-box the-box4" id="the-box-inspection-creation">INSPECCIONES - CREACION</div>
//         <div onClick={the_box_public_map} className="the-box the-box4" id="the-box-public-map">MAPA PUBLICO</div>
//         <div onClick={the_box_public_notification} className="the-box the-box4" id="the-box-public-notification">NOTIFICACION</div>
//         <div onClick={the_box_public_region_limit} className="the-box the-box4" id="the-box-public-region-limit">LIMITES REGIONALES</div>
//         <div onClick={the_box_public_susceptibility} className="the-box the-box4" id="the-box-public-susceptibility">SUSCEPTIBILIDAD MUNICIPAL AMIANTO</div>
//     </div>
   
//       {/* <label>andando</label>
//       <Button onClick={swichGeometry}>Cambiar geometrya</Button> */}
//       <div style={{resize: "both", width:"60vw", height:"50vh", overflow:"hidden"}}>
//       <OpenLayersMap

// geojsons ={geojsons}
// style = {{ width: "100%", height: "100%", display: "flex" }}
// // strokeColor = "#CACACA",
// // strokeHoverColor = "#0000FF"
// // strokeWidth = {0.5}
// // fillColor = "0, 0, 255"
// // fillPolygonsOpacity = "0.85"
// // fillPointsOpacity = "0.8"
// defaultSelectedLayer = "solar_heatmap"
// //layers_data = {layerStyle}
// //getBgColorFromFeature = {(feature)=>{return  "116, 86, 171"}}
// mustShowOnClickFeature = {dataMustShowOnClickFeature}
// isDrawEnabled = {dataIsDrawEnabled}
// isDrawEnabledOnStartUp = {dataIsDrawEnabledOnStartup}
// //assetsBasePath = {false}
// isAdressSearchBarEnabled = {dataIsAddressSearchBarEnabled}
// isGetCoordinatesOnClickEnabled = {dataIsGetCoordinatesOnClickEnabled}
// isGeolocationEnabled = {isGeolocationEnabled}
// layersSelected ={dataLayerStyle}
// ineCode={"1001"}
// />
//       </div>
      
//     </div>
//   );
// }



export default App;
